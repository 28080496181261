import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { motion } from "framer-motion";
import { Menu, X } from "lucide-react";

export const Navbar = () => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth < 768);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const checkViewSide = () => {
      if (window.innerWidth < 768) {
        setIsPhoneScreen(true);
      } else {
        setIsPhoneScreen(false);
      }
    };

    // Check the initial screen size
    checkViewSide();

    // Add the resize event listener
    window.addEventListener("resize", checkViewSide);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkViewSide);
    };
  }, [isPhoneScreen]);

  return (
    <div>
      {isPhoneScreen ? (
        <div className="flex justify-end">
          <Menu
            size={50}
            className="text-teal-600 cursor-pointer"
            onClick={() => setIsOpen(true)}
          />
          {isOpen && (
            <motion.div
              className="fixed inset-0 z-40 "
              initial={{ x: 200 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div
                className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm"
                onClick={() => setIsOpen(false)}
              ></div>
              <div className="absolute right-0 w-3/4 h-full bg-slate-900 z-50 p-6">
                <motion.div
                  initial={{ x: 200 }}
                  animate={{ x: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <X
                    className="absolute top-4 right-4 text-teal-500 cursor-pointer"
                    size={50}
                    onClick={() => setIsOpen(false)}
                  />
                </motion.div>{" "}
                <div className="mt-12 h-full flex flex-col justify-center items-center space-y-10 font-title  text-center">
                  <div className="flex flex-col">
                    <span className="text-xl text-teal-400 cursor-pointer">
                      01{" "}
                    </span>
                    <h1 className="text-xl">About</h1>
                  </div>
                  <div className="flex flex-col space-x-3">
                    <span className="text-xl text-teal-400 cursor-pointer">
                      02{" "}
                    </span>
                    <h1 className="text-xl">Works</h1>
                  </div>
                  <div className="flex  flex-col space-x-3">
                    <span className="text-xl text-teal-400 cursor-pointer">
                      03{" "}
                    </span>
                    <h1 className="text-xl">Contact</h1>
                  </div>
                  <button className="btn text-teal-400 border-2 border-teal-700 px-10 hover:bg-teal-600 hover:text-white">
                    Resume
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      ) : (
        <div className="hidden md:flex justify-between w-[70%] mx-auto py-2 font-title">
          <motion.div
            className=""
            initial={{
              x: -100,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <p className="logo bg-teal-600 px-5 py-3 text-2xl font-bold cursor-pointer">
              K
            </p>
          </motion.div>
          <motion.div
            className="flex flex-row space-x-4 justify-center items-center"
            initial={{
              x: 20,
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <div className="flex space-x-2 cursor-pointer hover:text-teal-600">
              <p className="text-teal-500 font-semibold ">01.</p>
              <p> About</p>
            </div>
            <div className="flex space-x-2 cursor-pointer hover:text-teal-600">
              <p className="text-teal-500 font-semibold">02. </p>
              <p> Works</p>
            </div>
            <div className="flex space-x-2 cursor-pointer hover:text-teal-600">
              <p className="text-teal-500 font-semibold">03. </p>
              <p> Contact</p>
            </div>
            <div>
              <button className="btn text-teal-400 border-2 border-teal-700 px-6 hover:bg-teal-600 hover:text-white">
                Resume
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};
