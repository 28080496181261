import { Navbar } from "./component/Navbar";
import { AboutMe } from "./page/AboutMe";
import { Experience } from "./page/Experience";
import { Hero } from "./page/Hero";
import { Home } from "./page/Home";
import { Facebook, Github, Linkedin, Mail } from "lucide-react";
import { animate, motion } from "framer-motion";
import { ParticleComponent } from "./component/ParticleComponent";
function App() {
  const HandleClick = (url) => {
    window.location.href = url;
  };
  return (
    <div className="h-screen overflow-auto">
      <motion.div></motion.div>
      <ParticleComponent />
      <Navbar />
      <Hero />
      <AboutMe />
      <Experience />
      <Home />
      <motion.div
        className="hidden md:block absolute bottom-32 right-0 z-50 p-2 rotate-90"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p className="font-title">cheatchansovoth@gmail.com</p>
        <motion.div
          className="h-1 bg-teal-400"
          initial={{ width: 250 }}
          animate={{ width: 120 }}
          transition={{ duration: 1 }}
        ></motion.div>
      </motion.div>
      <div className="hidden md:block absolute bottom-32 left-20 z-50 p-2 bg-teal-500 rounded-2xl py-3">
        <motion.div
          className="flex flex-col space-y-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Github
            className="text-white cursor-pointer"
            size={30}
            onClick={() => {
              HandleClick("https://github.com/cheatchansovoth/");
            }}
          />
          <Linkedin
            className="text-white cursor-pointer"
            size={30}
            onClick={() => {
              HandleClick("https://www.linkedin.com/in/chansovothcheat/");
            }}
          />
          <Mail
            className="text-whitr cursor-pointer"
            size={30}
            onClick={() => {
              HandleClick("mailto:cheatchansovoth@gmail.com");
            }}
          />
          <Facebook
            className="text-white cursor-pointer"
            size={30}
            onClick={() => {
              HandleClick("https://www.facebook.com/wwwwwk3n");
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}

export default App;
