import React, { useState } from "react";
import { motion } from "framer-motion";
export const Experience = () => {
  const [activeJob, setActiveJob] = useState("AFDC Connect");

  const jobs = [
    {
      company: "AFDC Connect",
      title: "Picker Packer",
      period: "Setember 2024 - Present",
      details: [
        "Accurately selected and packed products for shipment",
        "Ensured timely and efficient processing of orders",
        "Maintained a clean and organized workspace to promote safety and productivity",
        "Collaborated with team members to meet daily packing targets and shipment deadlines",
      ],
    },
    {
      company: "Fully Study",
      title: "Auckland Univerisity of Technology",
      period: "2021-2023",
      details: [
        "Completed a Bachelor’s degree in Software Development at Auckland University of Technology from 2021 to 2023.",
        "Gained practical experience in web development through various projects, focusing on front-end and back-end technologies.",
        "Collaborated with peers on group assignments, enhancing teamwork and communication skills.",
      ],
    },
    {
      company: "Sonny Bakery",
      title: "Junior Baker",
      period: "Jan 2021 - Dec 2021",
      details: [
        " Communicated and assisted senior bake",
        " Incharge of preparation of pastries",
        " Assisted front of house if required (i.e., operating the till",
      ],
    },
    {
      company: "Ronnie Cafe",
      title: "Assistance Baker",
      period: "Jan 2020 - Dec 2020",
      details: [
        "Assisted senior baker with the preparation of pastries",
        "Incharge of meals preparation",
        "Incharge of inventory management",
        "Provided assistance with training new employees",
        " Assisted with front of house if required (i.e., serving customers)",
      ],
    },
  ];

  return (
    <motion.div
      className="w-[90%] xl:w-[50%] mx-auto min-h-[50vh] flex flex-col justify-center font-title"
      initial={{
        opacity: 0,
      }}
      whileInView={{ opacity: 1 }}
      transition={{
        duration: 1,
      }}
    >
      <div className="flex space-x-2 items-center">
        <p className="text-2xl md:text-4xl text-teal-400">02.</p>
        <h1 className="text-2xl md:text-4xl ">Where I've worked</h1>
        <div class="flex-1 ml-4 border-t border-gray-600 max-w-[420px]"></div>
      </div>
      <div className="bg-navy-900 text-gray-300 p-8 min-h-[40vh]">
        <div className="flex">
          <div className="w-1/4 relative">
            {jobs.map((job, index) => (
              <button
                key={index}
                className={`block text-left py-2 px-4 w-full ${
                  activeJob === job.company ? "text-teal-300" : "text-gray-500"
                }`}
                onClick={() => setActiveJob(job.company)}
              >
                {job.company}
              </button>
            ))}
            <div
              className="absolute left-0 w-0.5 bg-teal-300 transition-all duration-300 ease-in-out"
              style={{
                top: `${
                  jobs.findIndex((job) => job.company === activeJob) * 40
                }px`,
                height: "40px",
              }}
            />
          </div>
          <div className="w-3/4 pl-8">
            {jobs.find((job) => job.company === activeJob) && (
              <motion.div
                key={activeJob}
                initial={{
                  opacity: 0,
                }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 2,
                }}
              >
                <h3 className="text-xl font-semibold">
                  {jobs.find((job) => job.company === activeJob).title}
                </h3>
                <p className="text-sm text-gray-400 mb-4">
                  {jobs.find((job) => job.company === activeJob).period}
                </p>
                <ul className="list-disc list-inside text-gray-400">
                  {jobs
                    .find((job) => job.company === activeJob)
                    .details.map((detail, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        initial={{
                          opacity: 0,
                        }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1,
                        }}
                      >
                        {detail}
                      </li>
                    ))}
                </ul>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
