import React from "react";
import p1 from "../assets/p2.jpg";
import { motion } from "framer-motion";
export const AboutMe = () => {
  return (
    <motion.div
      className="w-[90%] xl:w-[50%] mx-auto flex min-h-[50vh]"
      initial={{
        opacity: 0,
      }}
      whileInView={{ opacity: 1 }}
      transition={{
        duration: 1,
      }}
    >
      <div className="flex flex-col space-y-4 justify-center">
        <div className="flex space-x-2 items-center">
          <p className="text-2xl md:text-4xl text-teal-400">01.</p>
          <h1 className="text-2xl md:text-4xl font-title">ABOUT ME</h1>
          <div class="flex-1 ml-4 border-t border-gray-600 max-w-[420px]"></div>
        </div>
        <div className="mx-auto md:flex space-x-2 space-y-3">
          <div>
            <p className="text-md max-w-[720px] text-gray-400 font-title">
              Hello! My name is Chansovoth Cheat, and I recently graduated from
              Auckland University of Technology, where I majored in software
              development. My journey in web development started during my
              studies, where I learned to build applications using modern web
              technologies. I have a strong passion for coding and
              problem-solving, and I'm eager to apply my skills in real-world
              projects. Since graduation, I've been sharpening my skills and
              working on personal projects, building my knowledge in JavaScript
              frameworks, backend development, and user interface design. I'm
              excited to dive into the tech industry and contribute to
              innovative projects that make an impact. <br></br>Here are a few
              technologies I've been working with recently:
            </p>
          </div>
          <div className="">
            <img
              src={p1}
              alt="profile"
              className="rounded-2xl md:rounded-none filter hover:saturate-150 duration-500 max-w-[420px] max-h-[320px] shadow-2xl shadow-teal-500"
            ></img>
          </div>
        </div>
        <div className="md:w-2/6 px-4 font-title text-gray-400">
          <ul className="list-disc grid grid-cols-2">
            <li>HTML</li>
            <li>CSS</li>
            <li>JQUERY</li>
            <li>Node.js Express</li>
            <li>React.js</li>
            <li>Tailwindcss</li>
            <li>Bootstrap</li>
            <li>DaisyUI Framework</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};
