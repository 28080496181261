import React from "react";
import { Folder, ExternalLink, GitFork } from "lucide-react";
import { motion } from "framer-motion";
const ProjectCard = ({ title, description, tags, github, url, note }) => {
  const RedirectUrl = (url) => {
    window.location.href = url;
  };
  return (
    <div className="bg-gray-800 rounded-lg p-6 flex flex-col h-full  hover:scale-105 duration-300 border-b-4 border-l-2 border-teal-600">
      <div className="flex justify-between items-start mb-4">
        <Folder className="text-teal-400" size={24} />
        <div className="flex space-x-2">
          <GitFork
            className="text-gray-400 hover:text-white cursor-pointer"
            size={20}
            onClick={() => {
              RedirectUrl(github);
            }}
          />
          <ExternalLink
            className="text-gray-400 hover:text-white cursor-pointer"
            size={20}
            onClick={() => {
              RedirectUrl(url);
            }}
          />
        </div>
      </div>

      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <span className="text-sm text-teal-900">{note}</span>
      <p className="text-gray-400 mb-4 flex-grow">{description}</p>
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <span key={index} className="text-xs text-teal-400">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export const Home = () => {
  const projects = [
    {
      title: "Anime Streaming Website",
      description:
        "Stream the latest anime episodes in high-quality with seamless navigation and user-friendly design",
      tags: ["React.js", "TailwindCSS", "DaisyUI", "Consumet API"],
      github: "https://github.com/cheatchansovoth/9cloudani",
      url: "https://anime.cheatchansovoth.com/",
      note: "Educational Purpose Only",
    },
    {
      title: "Movies Streaming Website",
      description:
        "Discover and stream the latest movies with detailed info powered by TheMovieDB API.",
      tags: ["React.js", "TailwindCSS", "DaisyUI", "TheMovieDB"],
      github: "https://github.com/cheatchansovoth/9cloudmovies",
      url: "https://movies.cheatchansovoth.com/",
      note: "Educational Purpose Only",
    },
    {
      title: "Online Clothes Shopping",
      description:
        "Build a sleek online clothing store with React.js, TailwindCSS, and secure payments powered by Stripe.",
      tags: [
        "React.js",
        "TailwindCSS",
        "DaisyUI",
        "Firebase",
        "Stripe Payment",
      ],
      github: "https://github.com/cheatchansovoth/Clockson",
      url: "https://clockson.vercel.app/",
      note: "Educational Purpose Only",
    },
  ];
  return (
    <div className="bg-gray-900 p-8 md:w-[60%] mx-auto font-title">
      <h2 className="text-2xl font-bold text-white mb-4 text-center">
        Other Noteworthy Projects
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {projects.map((project, index) => (
          <motion.div
            initial={{ x: 50 + index }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.5 + index }}
            key={index}
          >
            <ProjectCard key={index} {...project} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};
