import React from "react";
import { Facebook, Github, Linkedin, Mail } from "lucide-react";
import { motion } from "framer-motion";
export const Hero = () => {
  const handleContact = () => {
    window.location.href = "mailto:cheatchansovoth@gmail.com";
  };
  const HandleClick = (url) => {
    window.location.href = url;
  };
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 3,
      }}
    >
      <div className="w-[80%] md:w-[70%] mx-auto h-screen flex justify-center items-center">
        <div className="space-y-3 font-title">
          <p className="text-xl text-teal-400 font-semibold">Hi, my name is</p>
          <h1 className="text-3xl md:text-6xl font-bold">Chansovoth Cheat.</h1>
          <h2 className="text-3xl md:text-6xl font-semibold text-gray-400">
            I build things for the web.
          </h2>
          <div className="space-y-10">
            <p className="max-w-[480px] text-gray-400">
              I'm a software developer specializing in building exceptional
              digital experiences. Currently, I'm focused on creating
              user-friendly, accessible web applications using technologies like
              React.js and TailwindCSS. I aim to deliver human-centered products
              that enhance user engagement and satisfaction.
            </p>

            <button
              className="btn text-teal-400 border-2 border-teal-700 px-6 hover:bg-teal-600 hover:text-white"
              onClick={() => handleContact()}
            >
              Contact me
            </button>
          </div>
          <div>
            <div className="flex flex-row space-x-3 md:hidden">
              <Github
                className="text-white cursor-pointer"
                size={30}
                onClick={() => {
                  HandleClick("https://github.com/cheatchansovoth/");
                }}
              />
              <Linkedin
                className="text-white cursor-pointer"
                size={30}
                onClick={() => {
                  HandleClick("https://www.linkedin.com/in/chansovothcheat/");
                }}
              />
              <Mail
                className="text-whitr cursor-pointer"
                size={30}
                onClick={() => {
                  HandleClick("mailto:cheatchansovoth@gmail.com");
                }}
              />
              <Facebook
                className="text-white cursor-pointer"
                size={30}
                onClick={() => {
                  HandleClick("https://www.facebook.com/wwwwwk3n");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
